import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { inject, observer } from "mobx-react";
import ReactHtmlParser from "react-html-parser";
import { ApolloProvider, Query } from "react-apollo";

import { Mutation } from "react-apollo";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import Link from "lib/Link";

import {
  arrangeData,
  encodeJsonString,
} from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";

const GetHoldings = gql`
  query holding($searchForm: IntegrationSearchForm) {
    EdsSearchHoldings(Input: $searchForm) {
      Results {
        Available
        Callnumber
        CanReserve
        Barcode
        HoldID
        Location
        Publicnote
      }
    }
  }
`;

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

class EDSHoldingsComp extends React.Component {
  constructor(props) {
    super(props);
  }

  toggleAccordion = (e) => {
    e.persist();
    e.target.classList.toggle("turnicon");
    let turnicon = e.target.classList.contains("turnicon") ? "" : "none";
    this.tableList.style.display = turnicon;
  };
  render() {
    const { an, dbid, isbn, issn, t } = this.props;
    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          query={GetHoldings}
          fetchPolicy="network-only"
          variables={{
            searchForm: {
              an,
              dbid,
              standardno: `${isbn}${issn}`,
            },
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return (
                <div className="status">{t("jumperrwd.hold.loading")}</div>
              );
            }

            if (error) {
              return "Error!" + error.message;
            }

            if (!data.EdsSearchHoldings) {
              return <div className="status"></div>;
            }

            const { Results = [] } = data.EdsSearchHoldings;
            let aviableCnt = 0;

            if (Results.length == 0) {
              return <div className="status"></div>;
            }

            const HoldBlock = Results.map((v, k) => {
              const {
                Available,
                Callnumber,
                CanReserve,
                Barcode,
                Location,
                Publicnote,
              } = v;
              aviableCnt += parseInt(Available);
              return (
                <tr>
                  <td data-title={"#"}>{k + 1}</td>
                  <td data-title={t("jumper.common.callnumber")}>
                    {Callnumber}
                  </td>
                  <td data-title={t("jumper.common.locations")}>{Location}</td>
                  <td data-title={t("jumper.common.status")}>{Publicnote}</td>
                </tr>
              );
            });

            return (
              <>
                <div className="status">
                  <button
                    type="button"
                    tabIndex="0"
                    className="popover"
                    onClick={this.toggleAccordion}
                  >
                    {t("jumper.common.status")}
                  </button>
                  {aviableCnt > 0 && (
                    <span className="tip">
                      {t("jumper.common.available.borrow")}
                    </span>
                  )}
                </div>
                <div
                  className="popover_content table_list"
                  ref={(c) => {
                    this.tableList = c;
                  }}
                  style={{ display: "none" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>{"#"}</th>
                        <th>{t("jumper.common.callnumber")}</th>
                        <th>{t("jumper.common.locations")}</th>
                        <th>{t("jumper.common.status")}</th>
                      </tr>
                    </thead>
                    <tbody>{HoldBlock}</tbody>
                  </table>
                </div>
              </>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

EDSHoldingsComp.propTypes = {};

EDSHoldingsComp.defaultProps = {};

export default EDSHoldingsComp;
