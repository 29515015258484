import IntegrationDataList from "components/list/IntegrationDataList";
import IntegrationSearchDisplayComp from "components/resource/IntegrationSearchDisplayComp";
import { navigate } from "gatsby";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { inject, observer } from "mobx-react";
import qs from "query-string";
import React from "react";
import autobind from "react-decoration/lib/decorators/functions/autobind";
import SearchRelatedModal from "./SearchRelatedModal";
import { Interpolate } from "react-i18next";

const getIntegrationSearchList = gql`
  query startIntegrationSearch($searchForm: IntegrationSearchForm) {
    startIntegrationSearch(Input: $searchForm) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        filterPid
        searchKeys
        showNext
        showPrevious
        iTotal
        iFilterTotal
        process
        fetchMoreProcess
        total
        iTotal
        pid
        isExpired
        showNext
        fetchMore
        userCollectionList

        pages {
          active
          val
        }
      }
    }
  }
`;

const addBookmark = gql`
  mutation addBookmark($form: [AddBookmarkItem]) {
    addBookmark(Input: $form) {
      success
      message
    }
  }
`;

const deleteBookmarkBySourceID = gql`
  mutation deleteBookmarkBySourceID($ids: [String]) {
    deleteBookmarkBySourceID(ids: $ids) {
      success
      message
    }
  }
`;

class Queue {
  constructor() {
    this.taskList = [];
    this.runnering = false;
  }

  put = (event) => {
    this.taskList.push(event);
  };

  start = async () => {
    console.log("taskQueue:", this.taskList);
    if (this.taskList.length > 0) {
      await this.taskList[0]();
      await this.taskList.splice(0, 1);
      if (this.taskList.length > 0) {
        this.start();
      }
    }
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  stop = () => {
    this.taskList = [];
  };
}

@inject("readerStore", "appStore")
@observer
class IntegrationSearchListComp extends React.Component {
  constructor(props) {
    super(props);
    let params = qs.parseUrl(this.props.location.href).query;

    let {
      searchInput,
      searchField,
      op,
      db,
      filterField,
      filterValue,
      pid,
      filterDBID,
      fetch,
      pageNo = 1,
      sort,
      mode,
      centralized,
      decentralized,
      searchBlockType,
    } = params;

    searchInput = searchInput || [];
    searchField = searchField || [];
    filterField = filterField || [];
    filterValue = filterValue || [];
    op = op || [];
    db = db || [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof filterField === "string") {
      filterField = [filterField];
    }
    if (typeof filterValue === "string") {
      filterValue = [filterValue];
    }
    if (typeof op === "string") {
      op = [op];
    }
    if (typeof db === "string") {
      db = [db];
    }

    ////op.splice(0, 0, '');

    this.state = {
      searchForm: {
        searchInput,
        searchField,
        op,
        filterField,
        filterValue,
        pid,
        db,
        filterDBID,
        fetch,
        limit: 30,
        pageNo,
        sort,
        mode,
        searchBlockType,
      },
      centralized,
      decentralized,
      isAllTag: false,
      allIds: [],
      allDatas: [],
      modalVisible: false,
    };
  }

  getRefetch = (refetch) => {
    this.refetch = () => {
      console.log("PID:",this.state.searchForm.pid)
      refetch();
    };
  };

  componentDidMount() {
    window.a = this;
  }

  @autobind
  onQueryCompleted(data) {
    console.info("onQueryCompleted");
    const {
      pid,
      filterPid,
      process,
      fetchMoreProcess = false,
      iTotal,
      total,
      fetchMore,
      userCollectionList,
    } = data.startIntegrationSearch.info;
    const { searchForm } = this.state;
    this.filterTotal.innerHTML = `${total}`;
    this.iTotal.innerHTML = iTotal;

    if (process == true) {            
      if (pid != searchForm.pid) {
        this.setState({ searchForm: { ...searchForm, pid } },()=>{
          if (window.IntervalID == null) {
            window.IntervalID = setInterval(this.refetch, 1000);
          }
          let params = qs.parseUrl(this.props.location.href).query;
          params.pid = pid;
          navigate(["/searchResult", qs.stringify(params)].join("?"));
        });
      }
    } else {
      let params = qs.parseUrl(this.props.location.href).query;
      if (filterPid != params.filterPid) {
        params.filterPid = filterPid;
        params.pid = pid;
        navigate(["/searchResult", qs.stringify(params)].join("?"));
      }
      clearInterval(window.IntervalID);
      window.IntervalID = null;
    
      /*
      var tagNum = 0;
      var ids = [];
      var datas = [];
      data.startIntegrationSearch.list.values.map((value) => {
        var values = [];
        value.ref.map((kv) => {
          if(kv.key === "targetid") {
            ids.push(kv.value);
            if (userCollectionList !== null) {
              if (userCollectionList.includes(kv.value)) {
                tagNum ++;
              }
            }
          }

          var item = kv;
          delete item["__typename"]
          values.push(item);
        })
        datas.push({ type: "websearch", values: values });
      })
        
      if ( this.state.isAllTag != (tagNum == data.startIntegrationSearch.list.values.length) || ids.length != this.state.allIds.length || datas.length != this.state.allDatas.length ) {
        this.setState({
          isAllTag: tagNum == data.startIntegrationSearch.list.values.length,
          allIds: ids,
          allDatas: datas,
        })
      }*/
    }
    console.log("onQueryCompleted refetch onQueryCompleted");
  }
  componentWillReceiveProps(props) {
    /*
    let pid = props.appStore.integrationPid;
    
    if (pid == null){
      pid = "";
    } */
    let params = qs.parseUrl(window.location.href).query;
    let {
      searchInput,
      searchField,
      op,
      filterField,
      filterValue,
      pid,
      db,
      fetch,
      filterDBID,
      pageNo = 1,
      sort,
      mode,
      centralized,
      decentralized,
      searchBlockType,
    } = params;

    searchInput = searchInput || [];
    searchField = searchField || [];
    op = op || [];
    db = db || [];
    filterField = filterField || [];
    filterValue = filterValue || [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof op === "string") {
      op = [op];
    }
    if (typeof db === "string") {
      db = [db];
    }
    if (typeof filterField === "string") {
      filterField = [filterField];
    }
    if (typeof filterValue === "string") {
      filterValue = [filterValue];
    }

    this.setState({
      searchForm: {
        searchInput,
        searchField,
        op,
        filterField,
        filterValue,
        pid,
        db,
        filterDBID,
        fetch,
        limit: 30,
        pageNo,
        sort,
        mode,
        searchBlockType,
      },
      centralized,
      decentralized,
    });
    //this.refetch();
  }

  toggleSortDisplay = (e) => {
    if (this.sortOptionList.style.display == "none") {
      this.sortOptionList.style.display = "block";
    } else {
      this.sortOptionList.style.display = "none";
    }
  };

  sort = (e) => {
    let params = qs.parseUrl(window.location.href).query;
    const { sort = "preview" } = params;
    this.sortOptionList.style.display = "none";
    if (e != sort) {
      params.sort = e;
      navigate([window.location.uri, qs.stringify(params)].join("?"));
    }
  };

  toggleFilterBlock = (e) => {
    e.persist();
    let container = e.target.closest(".container");
    container.querySelector(".filter_search").style = "display: block; top:0;";
  };

  @autobind
  addAll(e) {
    e.preventDefault();
    if (this.props.readerStore.auth) {
      var datas = [];
      let {
        values,
      } = window.dataList.Query.current.getQueryResult().data.startIntegrationSearch.list;
      values.map((value) => {
        var items = [];
        value.ref.map((kv) => {
          items = items.concat({ key: kv.key, value: kv.value });
        });

        let { search } = this.props;
        let searchBlockType = search.searchBlockType || "integration";
        if (searchBlockType == "hold") {
          datas = datas.concat({ type: "collection", values: items });
        } else {
          datas = datas.concat({ type: "websearch", values: items });
        }
      });

      client.jumperrwdClient
        .mutate({
          mutation: addBookmark,
          variables: { form: datas },
        })
        .then((res) => {
          if (res.data.addBookmark.success) {
            alert(this.props.t("jumperrwd.common.addCollectionSuccess"));
          } else {
            alert(
              this.props.t("jumperrwd.common.addCollectionFailed") +
                " " +
                res.data.addBookmark.message
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.props.appStore.openLogin();
    }
  }

  @autobind
  deleteAll(e) {
    e.preventDefault();
    if (this.props.readerStore.auth) {
      client.jumperrwdClient
        .mutate({
          mutation: deleteBookmarkBySourceID,
          variables: { ids: this.state.allIds },
        })
        .then((res) => {
          if (res.data.deleteBookmarkBySourceID.success) {
            this.refetch();
          } else {
            alert(
              this.props.t("jumperrwd.common.deleteCollectionFailed") +
                res.data.deleteBookmarkBySourceID.message
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.props.appStore.openLogin();
    }
  }

  render() {
    let { searchForm, centralized, decentralized } = this.state;
    let params = qs.parseUrl(window.location.href).query;
    let { sort = "preview", filterField, db } = params;
    const { t } = this.props;

    let isAllTag =
      this.state.isAllTag &&
      this.iTotal != undefined &&
      parseInt(this.iTotal.innerHTML) > 0;

    let sortMap = {
      preview: t`hyint.common.table.default`,
      title: t`jumper.common.table.title`,
      author: t`jumper.common.table.author`,
      date: t`jumper.common.table.publicationdate`,
      url_fulltext: t`jumper.common.table.fulltextexist`,
      relevance: t`jumper.common.table.relevance`,
    };

    filterField = filterField || [];
    db = db || [];
    if (typeof filterField === "string") {
      filterField = [filterField];
    }
    if (typeof db === "string") {
      db = [db];
    }

    let filterNonDBNum = filterField.filter((f) => {
      if (f != "db") {
        return 1;
      }
    });
    let filterDBNum = filterField.filter((f) => {
      if (f == "db") {
        return 1;
      }
    });
    let showSort = true;

    if (
      filterNonDBNum.length == 0 &&
      (db.length == 1 || filterDBNum.length == 1)
    ) {
      showSort = false;
    }

    if (!this.props.display) {
      return null;
    }
    return (
      <>
        <div className="function_panel">
          <div className="result_amount">
            {t("jumperrwd.common.searchResult")}：
            <div className="search_word"></div>
            <div className="total">
              <Interpolate
                t={t}
                i18nKey="jumperrwd.integration.totalAmount"
                parent={"total"}
                num={
                  <em
                    style={{ color: "#E15706" }}
                    ref={(c) => {
                      this.iTotal = c;
                    }}>
                    0
                  </em>
                }
              />
            </div>
            <div className="filter_num">
              <Interpolate
                t={t}
                i18nKey="jumperrwd.integration.currentFilterAmount"
                parent={"filter"}
                num={
                  <em
                    style={{ color: "#E15706" }}
                    ref={(c) => {
                      this.filterTotal = c;
                    }}>
                    0
                  </em>
                }
              />
            </div>
            <a
              className="relative_subject"
              tabIndex="0"
              onClick={() => this.setState({ modalVisible: true })}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ modalVisible: true });
                }
              }}>
              {t("jumperrwd.common.seeRelatedSubject")}
            </a>
          </div>

          <div className="function_block">
            {showSort && (
              <div className="option">
                <div className="name">
                  <a
                    tabIndex="0"
                    href="javascript:;"
                    onClick={this.toggleSortDisplay}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.toggleSortDisplay(e);
                      }
                    }}>
                    {t("jumperrwd.common.sortType")} : {sortMap[sort]}
                  </a>
                </div>
                <div
                  className="option_list"
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.sortOptionList = c;
                  }}>
                  <ul>
                    <li>
                      <a
                        tabIndex="0"
                        className={sort == "preview" ? "active" : ""}
                        onClick={() => {
                          this.sort("preview");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sort("preview");
                          }
                        }}>{t`hyint.common.table.default`}</a>
                    </li>
                    <li>
                      <a
                        tabIndex="0"
                        className={sort == "title" ? "active" : ""}
                        onClick={() => {
                          this.sort("title");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sort("title");
                          }
                        }}>{t`jumper.common.table.title`}</a>
                    </li>
                    <li>
                      <a
                        tabIndex="0"
                        className={sort == "author" ? "active" : ""}
                        onClick={() => {
                          this.sort("author");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sort("author");
                          }
                        }}>{t`jumper.common.table.author`}</a>
                    </li>
                    <li>
                      <a
                        tabIndex="0"
                        className={sort == "date" ? "active" : ""}
                        onClick={() => {
                          this.sort("date");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sort("date");
                          }d
                        }}>{t`jumper.common.table.publicationdate`}</a>
                    </li>
                    <li>
                      <a
                        tabIndex="0"
                        className={sort == "url_fulltext" ? "active" : ""}
                        onClick={() => {
                          this.sort("url_fulltext");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sort("url_fulltext");
                          }d
                        }}>{t`jumper.common.table.fulltextexist`}</a>
                    </li>
                    <li>
                      <a
                        tabIndex="0"
                        className={sort == "relevance" ? "active" : ""}
                        onClick={() => {
                          this.sort("relevance");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sort("relevance");
                          }d
                        }}>{t`jumper.common.table.relevance`}</a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <button
              type="button"
              tabIndex="0"
              className="filter_search_btn"
              onClick={this.toggleFilterBlock}>
              <i className="i_filter"></i>
            </button>
            {/*
            <button
              type="button"
              className={
                !isAllTag ? "add_bookmark" : "add_bookmark active"
              }
              onClick={!isAllTag ? this.addAll : this.deleteAll}>
              <i className="i_bookmark"></i>
              <span>{!isAllTag ? "全選加入書籤" : "全選刪除書籤"}</span>
            </button>
            */}
          </div>
        </div>
        <div className="list_all">
          <IntegrationDataList
            query={getIntegrationSearchList}
            variables={{
              searchForm,
            }}
            getRefetch={this.getRefetch}
            fetchPolicy={"network-only"}
            displayComp={IntegrationSearchDisplayComp}
            pagination={"IntegrationPagination"}
            onQueryCompleted={this.onQueryCompleted}
          />
        </div>

        <SearchRelatedModal
          searchKey={searchForm.searchInput[0]}
          visible={this.state.modalVisible}
          close={() => this.setState({ modalVisible: false })}
          t={t}
        />
      </>
    );
  }
}

export default IntegrationSearchListComp;
