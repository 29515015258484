import React from "react";
import ReactHtmlParser from "react-html-parser";
import { ApolloProvider, Query } from "react-apollo";
import qs from "query-string";
import gql from "graphql-tag";
import { Interpolate } from "react-i18next";
import { navigate } from "gatsby";

import client from "lib/ApolloClient";
import { withI18next } from "lib/withI18next";
import {
  arrangeData,
  encodeJsonString,
} from "components/list/action/action-util";
//import Link from 'next/link';

const GetPublication = gql`
  query publication($searchForm: IntegrationSearchForm, $form: SearchForm) {
    EdsPublication(Input: $searchForm) {
      Publication {
        Title
        PLink
        PublicationID
      }
      Research {
        Image
        Title
        PLink
        TitleResource
        Description
        Subjects {
          Text
          url
        }
      }
    }

    dbResourceList: getDBResourceList(Input: $form) {
      list {
        values {
          ref {
            key
            value
          }
        }
      }
    }
  }
`;

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

@withI18next(["common"])
class EDSPublication extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(props) {
    let params = qs.parseUrl(window.location.href).query;
    const { eid: pid } = params;
  }

  submitPublicationFind = () => {
    let publication_id = this.publication_id.value;
    let publication_label = this.publication_label.value;
    let searchkey = this.searchkey.value;
    let params = qs.parseUrl(window.location.href).query;
    delete params.searchInput;
    delete params.searchField;
    delete params.op;
    delete params.filterField;
    delete params.filterValue;
    delete params.eid;
    params.searchInput = [searchkey];
    params.searchField = ["KW"];
    params.publicationId = publication_id;
    params.publicationLabel = publication_label;
    window.scrollTo(0, 0);
    navigate(["/searchResult", qs.stringify(params)].join("?"));
  };

  toggleAccordion = (e) => {
    e.persist();
    e.target.classList.toggle("turnicon");
    let turnicon = e.target.classList.contains("turnicon") ? "" : "none";
    let accordion_content = e.target.closest(".accordion_header");
    accordion_content.nextElementSibling.style.display = turnicon;
  };

  render() {
    const { t, popup } = this.props;
    let params = qs.parseUrl(window.location.href).query;
    let {
      eid: pid = "",
      publicationId = "",
      searchInput,
      searchField,
      op,
    } = params;

    searchInput = searchInput || [];
    searchField = ["ebname"];
    op = [];

    
    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof op === "string") {
      op = [op];
    }

    searchInput = searchInput.slice(0,1)

    if (publicationId != "") {
      return null;
    }

    if (pid == "") {
      return "Loading....";
    }

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          query={GetPublication}
          fetchPolicy="network-only"
          variables={{
            searchForm: {
              pid,
            },
            form: {
              searchField,
              searchInput,
              op,
              searchTarget: "databases",
              pageType: "search",
              resourceType: "databases",
              showType: "simple",
            },
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <div className="toolsPanel">Loading....</div>;
            }

            if (error) {
              return "Error!" + error.message;
            }

            let { Publication, Research } = data.EdsPublication;
            let dbResourceList = data.dbResourceList.list.values;

            let dbBlock = dbResourceList.map((db) => {
              const arrangedData = arrangeData(db.ref);
              const { id, ebname, en_ebname } = arrangedData;
              return (
                <li>
                  <a
                    tabIndex="0"
                    onClick={() => {
                      popup(id);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        popup(id);
                      }
                    }}
                  >
                    {ebname}
                  </a>
                </li>
              );
            });

            let public_title = "",
              public_link = "",
              public_id = "",
              publication_label = "";
            if (Publication != null) {
              let { Title, PLink, PublicationID } = Publication;
              public_title = ReactHtmlParser(Title);
              publication_label = htmlDecode(Title);
              public_link = PLink;
              public_id = PublicationID;
            }

            let RS_Image = "",
              RS_Title = "",
              RS_PLink = "",
              RS_TitleResource = "",
              RS_Description = "";
            let subjectBlock = [];

            if (Research != null) {
              let {
                Image,
                Title,
                PLink,
                TitleResource,
                Description,
                Subjects,
              } = Research;

              RS_Title = ReactHtmlParser(Title);
              RS_Image = Image;
              RS_PLink = PLink;
              RS_TitleResource = ReactHtmlParser(TitleResource);
              RS_Description = ReactHtmlParser(Description);

              subjectBlock = Subjects.map((subject, key) => {
                return (
                  <li>
                    <a href={subject.url} target="_BLANK" title="另開新視窗">
                      {ReactHtmlParser(subject.Text)}
                    </a>
                  </li>
                );
              });
            }

            return (
              <>
                {Research && (
                  <div className="academic_vocabulary">
                    <h3 className="accordion_header ">
                      <a
                        href="javascript:;"
                        tabIndex="0"
                        className="turnicon"
                        onClick={this.toggleAccordion}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.toggleAccordion(e);
                          }
                        }}
                      >
                        學術詞彙解釋
                      </a>
                    </h3>
                    <div
                      className="accordion_content"
                      style={{ display: "block" }}
                    >
                      <div className="content_block">
                        <div className="search_vocabulary">{RS_Title}</div>
                        <div className="pic">
                          <img src={RS_Image} alt="RS_Image" />
                        </div>
                        <div className="text">
                          <ul>
                            <li>
                              <p>{RS_Description}</p>
                              <a
                                href={RS_PLink}
                                className="more"
                                target="_BLANK"
                                title="另開新視窗"
                              >
                                更多
                              </a>
                            </li>
                          </ul>
                          {subjectBlock.length > 0 && (
                            <div className="other_subject">
                              其他主題：
                              <ul>{subjectBlock}</ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {Publication && (
                  <div className="relevant_info">
                    <h3 className="accordion_header">
                      <a
                        href="javascript:;"
                        tabIndex="0"
                        className="turnicon"
                        onClick={this.toggleAccordion}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.toggleAccordion(e);
                          }
                        }}
                      >
                        相關期刊
                      </a>
                    </h3>
                    <div
                      className="accordion_content"
                      style={{ display: "block" }}
                    >
                      <ul>
                        <li>
                          <a href={public_link} target="_BLANK" title="另開新視窗">
                            {public_title}
                          </a>
                        </li>
                      </ul>
                      <div className="form_grp form_flex">
                        <input
                          type="hidden"
                          value={public_id}
                          ref={(c) => {
                            this.publication_id = c;
                          }}
                        />

                        <input
                          type="hidden"
                          value={publication_label}
                          ref={(c) => {
                            this.publication_label = c;
                          }}
                        />

                        <input
                          type="text"
                          title="輸入關鍵字"
                          placeholder="請輸入關鍵字"
                          ref={(c) => {
                            this.searchkey = c;
                          }}
                          accesskey="S"
                        />
                        <input
                          tabIndex="0"
                          name=""
                          type="submit"
                          title="查詢"
                          value="查詢"
                          onClick={this.submitPublicationFind}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.submitPublicationFind(e);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="relevant_info">
                  <h3 className="accordion_header">
                    <a
                      href="javascript:;"
                      tabIndex="0"
                      className="turnicon"
                      onClick={this.toggleAccordion}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.toggleAccordion(e);
                        }
                      }}
                    >
                      相關資料庫
                    </a>
                  </h3>
                  <div className="accordion_content">
                    <ul>{dbBlock}</ul>
                  </div>
                </div>
              </>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

EDSPublication.propTypes = {};

EDSPublication.defaultProps = {};

export default EDSPublication;
