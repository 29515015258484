import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";

import {
  IntegrationSearchQueryGroup,
  IntegrationSearchListComp,
  EDSSearchListComp,
  EdsSearchQueryGroup,
  EDSSearchDetailLightBox,
  DBResourceDetailLightBox,
} from "components/resource";

import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import { page } from "components/page";
import { navigate } from "gatsby";
import qs from "query-string";

@withI18next(["common"])
@page
class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    let params = qs.parseUrl(this.props.location.href).query;
    delete params.filterPid;
    delete params.filterValue;
    delete params.filterValueName;
    delete params.filterField;
    delete params.pageNo;
    delete params.filterDBID;
    delete params.fetch;
    const { centralized, decentralized } = params;
    this.state = {
      centralized,
      decentralized,
      tabIndex: this.getTabIndex(),
      dbID: "",
    };

    //navigate(["/searchResult", qs.stringify(params)].join("?"));
  }

  componentWillReceiveProps(props) {
    let params = qs.parseUrl(window.location.href).query;
    const { centralized, decentralized } = params;
    this.setState({
      centralized,
      decentralized,
      tabIndex: this.getTabIndex(),
    });
  }

  selectTab = (tabIndex) => {
    let params = qs.parseUrl(this.props.location.href).query;
    params.tab = tabIndex;
    delete params.filterPid;
    delete params.filterValue;
    delete params.filterValueName;
    delete params.filterField;
    delete params.pageNo;
    delete params.filterDBID;
    delete params.fetch;
    navigate(["/searchResult", qs.stringify(params)].join("?"));
  };

  tabClass = (tab) => {
    return tab == this.state.tabIndex ? "active" : "";
  };

  getDisplay = (tab) => {
    return tab == this.state.tabIndex;
  };

  getTabIndex = () => {
    let params = qs.parseUrl(window.location.href).query;
    const { centralized, decentralized, tab = "" } = params;

    let tabIndex = "";
    if (tab != "") {
      tabIndex = tab;
    } else if (centralized == "Y") {
      tabIndex = "center";
    } else if (centralized == "N" && decentralized == "Y") {
      tabIndex = "other";
    }
    return tabIndex;
  };

  popupDbDetailLightBox = (dbID) => {
    this.setState({ dbID }, () => {
      this.dbDetailLightBox.popup();
    });
  };

  render() {
    const {
      centralized = "N",
      decentralized = "N",
      tabIndex,
      dbID,
    } = this.state;
    let { search, t } = this.props;
    let searchBlockType = search.searchBlockType || "integration";
    return (
      <Layout {...this.props}>
        <div className="main lp">
          <SearchBlock searchType={searchBlockType} />
          <div className="main_content">
            <div className="container">
              <div className="filter_search">
                <IntegrationSearchQueryGroup
                  {...this.props}
                  display={this.getDisplay("other")}
                />
                <EdsSearchQueryGroup
                  {...this.props}
                  display={this.getDisplay("center")}
                />
              </div>
              <div className="search_result">
                <h2>{t("jumperrwd.common.searchResult")}</h2>
                <div className="tabSet">
                  <ul className="tabs">
                    <li
                      className={this.tabClass("center")}
                      style={{
                        display:
                          centralized === "Y" && decentralized === "Y"
                            ? ""
                            : "none",
                      }}
                    >
                      <a
                        tabIndex="0"
                        onClick={() => {
                          this.selectTab("center");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.selectTab("center");
                          }
                        }}
                      >
                        {t("jumperrwd.integration.centralized")}
                      </a>
                    </li>
                    <li
                      className={this.tabClass("other")}
                      style={{
                        display:
                          decentralized === "Y" && centralized === "Y"
                            ? ""
                            : "none",
                      }}
                    >
                      <a
                        tabIndex="0"
                        onClick={() => {
                          this.selectTab("other");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.selectTab("other");
                          }
                        }}
                      >
                        {t("jumperrwd.integration.decentralized")}
                      </a>
                    </li>
                  </ul>
                </div>

                <EDSSearchListComp
                  {...this.props}
                  display={this.getDisplay("center")}
                  getEdsDetailModal={() => {
                    return this.detailModal;
                  }}
                  popupDbDetailLightBox={this.popupDbDetailLightBox}
                />

                <IntegrationSearchListComp
                  {...this.props}
                  display={this.getDisplay("other")}
                />
              </div>
            </div>
          </div>
        </div>
        <EDSSearchDetailLightBox
          {...this.props}
          lightboxClass="detail_lightbox"
          blockClass="detailblock"
          ref={(c) => {
            this.detailModal = c;
          }}
        />

        <DBResourceDetailLightBox
          ref={(c) => {
            this.dbDetailLightBox = c;
          }}
          resourceType="databases"
          id={dbID}
          {...this.props}
        />
      </Layout>
    );
  }
}

export default SearchResult;
