import React from "react";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import { ApolloProvider, Query } from "react-apollo";
import ReactLoading from "react-loading";
import { navigate } from "gatsby";
import qs from "query-string";
import moment from "moment";
import client from "lib/ApolloClient";
import { inject, observer } from "mobx-react";
import { IntegrationSearchGroupLightBox } from "./";

const DisabledStyle = {
  "pointer-events": "none",
  opacity: "0.4",
  cursor: "not-allowed",
  display: "inline-block",
  "-webkit-filter": "grayscale(1)",
};

const getSearchQueryGroup = gql`
  query EdsSearchQueryGroup($searchForm: IntegrationSearchForm) {
    getSearchQueryGroup: EdsSearchQueryGroup(Input: $searchForm) {
      dateStart
      dateEnd
      queryGroupList {
        title
        index
        list {
          cnt
          title
          value
          dbID
        }
      }
    }
  }
`;

const toCurrency = (num) => {
  var parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

@inject("appStore", "readerStore")
@observer
class EdsSearchQueryGroup extends React.Component {
  constructor(props) {
    super(props);
    let params = qs.parseUrl(this.props.location.href).query;
    let {
      eid: filterPid,
      range = "",
      limiter,
      publicationId = "",
      publicationLabel = "",
    } = params;
    let ranges = range.split("/");

    let dateStart = "",
      dateEnd = "";
    dateStart = ranges[0];
    if (ranges.length > 1) {
      dateEnd = ranges[1];
    }

    limiter = [].concat(limiter || []);

    let { globalConfig } = props.appStore;
    let fulltextLimiterItems = JSON.parse(
      globalConfig["jumper.common.eds.fulltextLimiterItems"]
    ).item;

    this.state = {
      auth: props.readerStore.auth,
      filterPid: filterPid,
      fetchGo: -1,
      checkedAll: false,
      filters: [],
      groups: [],
      filterField: [],
      filterValue: [],
      filterValueName: [],
      keyword: "",
      limiter,
      dateStart,
      dateEnd,
      publicationId,
      publicationLabel,
      fulltextLimiterItems: fulltextLimiterItems.map((n) => n.toLowerCase()),
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {
    let params = qs.parseUrl(window.location.href).query;
    let {
      eid: filterPid = "",
      filterField = [],
      filterValue = [],
      filterValueName = [],
      limiter = [],
      publicationId = "",
      publicationLabel = "",
    } = params;

    if (this.state.filterPid != filterPid) {
      filterField = [].concat(filterField || []);
      filterValue = [].concat(filterValue || []);
      filterValueName = [].concat(filterValueName || []);
      limiter = [].concat(limiter || []);

      var groups = [];
      if (filterValue != null) {
        filterField.map((item, key) => {
          if (item != null)
            groups.push({
              index: item,
              title: filterValueName[key],
              keyword: filterValue[key],
            });
        });

        var group_to_values = groups.reduce((obj, item) => {
          obj[item.index] = obj[item.index] || { keyword: [], title: [] };
          obj[item.index].keyword.push(item.keyword);
          obj[item.index].title.push(item.title);
          return obj;
        }, {});

        groups = Object.keys(group_to_values).map((key) => ({
          index: key,
          keyword: group_to_values[key].keyword,
          title: group_to_values[key].title,
        }));
      }

      let filters = {};

      groups.map((v) => {
        v.keyword.map((v1) => {
          filters[v.index + "-" + v1] = true;
        });
      });

      this.setState({
        filterPid,
        filterField,
        filterValue,
        filterValueName,
        filters,
        groups,
        limiter,
        publicationId,
        publicationLabel,
      });
    }
    //sthis.trans();
  }

  @autobind
  trans() {
    let params = qs.parseUrl(this.props.location.href).query;
    let { filterPid = "" } = params;

    let { search } = this.props;
    params = { ...search };
    let { filterField, filterValue, filterValueName } = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    filterValueName = [].concat(filterValueName || []);

    var groups = [];
    if (filterValue != null) {
      filterField.map((item, key) => {
        if (item != null)
          groups.push({
            index: item,
            title: filterValueName[key],
            keyword: filterValue[key],
          });
      });

      var group_to_values = groups.reduce((obj, item) => {
        obj[item.index] = obj[item.index] || { keyword: [], title: [] };
        obj[item.index].keyword.push(item.keyword);
        obj[item.index].title.push(item.title);
        return obj;
      }, {});

      groups = Object.keys(group_to_values).map((key) => ({
        index: key,
        keyword: group_to_values[key].keyword,
        title: group_to_values[key].title,
      }));
    }

    let filters = {};

    groups.map((v) => {
      v.keyword.map((v1) => {
        filters[v.index + "-" + v1] = true;
      });
    });

    this.setState({
      filterPid: filterPid,
      filterField: filterField,
      filterValue: filterValue,
      filters: filters,
      groups: groups,
    });
  }

  @autobind
  addFilter(item, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    params.filterField = [].concat(params.filterField || []);
    params.filterValue = [].concat(params.filterValue || []);
    params.filterValueName = [].concat(params.filterValueName || []);

    let filters = this.state.filters;
    let key = "item.field" + "-" + "item.value";

    if (filters[key] != null) {
      delete filters[key];
      params = this.deleteSingleFilter(params, item);
    } else {
      if (item.field === "db") {
        filters = [];
        params.filterField = [];
        params.filterValue = [];
        params.filterValueName = [];
        params.filterDBID = "";
      }

      filters[key] = true;
      params = this.addSingleFilter(params, item);
    }

    if (item.field == "keyword") {
      this.setState({ keyword: "" });
    }

    document.querySelector(".filter_search").style = "";
    window.scrollTo(0, 0);
    navigate([location.uri, qs.stringify(params)].join("?"));
  }

  @autobind
  clearFilter() {
    let { location } = this.props;
    let params = this.deleteAllFilter();
    window.scrollTo(0, 0);
    navigate([location.uri, qs.stringify(params)].join("?"));
    document.querySelector(".filter_search").style = "";
  }

  @autobind
  deleteFilter(item) {
    let { location } = this.props;
    let params = this.getParams();
    params = this.deleteSingleFilter(params, item);
    window.scrollTo(0, 0);
    navigate([location.uri, qs.stringify(params)].join("?"));
    document.querySelector(".filter_search").style = "";
  }

  @autobind
  addSingleFilter(params, d) {
    params.filterField.push(d.field);
    params.filterValue.push(d.value);
    params.filterValueName.push(d.valueName);
    delete params.pageNo;
    if (d.field === "db") {
      params.filterDBID = d.dbID;
    }
    delete params.eid;
    document.querySelector(".filter_search").style = "";
    return params;
  }

  @autobind
  deleteSingleFilter(params, d) {
    /*$.each(params.filterField, function (k, v) {
      if (v == d.field && params.filterValue[k] == d.value) {
        params.filterField.splice(k, 1);i
        params.filterValue.splice(k, 1);
        params.filterValueName.splice(k, 1);
      }
    });*/
    params.filterField.map((v, k) => {
      if (v === d.field && params.filterValue[k] === d.value) {
        params.filterField.splice(k, 1);
        params.filterValue.splice(k, 1);
        params.filterValueName.splice(k, 1);
      }
    });
    delete params.pageNo;
    delete params.eid;
    document.querySelector(".filter_search").style = "";
    return params;
  }

  @autobind
  deleteAllFilter() {
    var params = this.getParams();
    delete params.filterField;
    delete params.filterValue;
    delete params.filterValueName;
    delete params.pageNo;
    delete params.filterPid;
    delete params.eid;
    delete params.range;
    delete params.publicationId;
    delete params.publicationLabel;
    return params;
  }

  @autobind
  getParams() {
    let { search } = this.props;
    let params = { ...search };
    params.filterField = [].concat(params.filterField || []);
    params.filterValue = [].concat(params.filterValue || []);
    params.filterValueName = [].concat(params.filterValueName || []);
    return params;
  }

  toggleAccordion = (e) => {
    e.persist();
    e.target.classList.toggle("turnicon");
    let turnicon = e.target.classList.contains("turnicon") ? "" : "none";
    let li = e.target.closest("li");
    li.querySelector(".accordion_content").style.display = turnicon;
  };

  @autobind
  groupUL(item) {
    const { filters } = this.state;
    const { t } = this.props;
    return item.list.map((field, key2) => {
      if (key2 < 5) {
        let checked = filters["item.index" + "-" + "field.value"] || false;
        const { cnt, title, value, dbID, total } = field;
        return (
          <>
            {!checked && (
              <li>
                <a
                  tabIndex="0"
                  onClick={this.addFilter.bind(this, {
                    field: item.index,
                    value,
                    valueName: field.title,
                    dbID,
                  })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.addFilter(
                        {
                          field: item.index,
                          value,
                          valueName: field.title,
                          dbID,
                        },
                        e
                      );
                    }
                  }}>
                  {title}
                  <span>
                    ({toCurrency(cnt)}
                    {total != null && `/${toCurrency(total)}`})
                  </span>
                </a>
              </li>
            )}
          </>
        );
      }
    });
  }

  @autobind
  handleKeyword(e) {
    e.preventDefault();
    this.setState({ keyword: e.target.value });
  }

  popup = (key) => {
    this.queryGroup.refs["gbox_" + key].popup();
  };

  closeFilterBlock = (e) => {
    e.persist();
    let container = e.target.closest(".container");
    container.querySelector(".filter_search").style = "";
  };

  dbFilter = (e, item) => {
    item.preventDefault();
    //alert(JSON.stringify(item))
    //this.addFilter(item);
  };

  limiterHandler = (e) => {
    let { location } = this.props;
    let { limiter } = this.state;
    let index = limiter.indexOf(e.target.value);
    if (e.target.checked) {
      limiter.push(e.target.value);
    } else {
      if (index > -1) {
        limiter.splice(index, 1);
      }
    }

    this.setState({ limiter });
    let params = qs.parseUrl(window.location.href).query;
    params.limiter = limiter;
    delete params.eid;
    window.scrollTo(0, 0);
    navigate(["/searchResult", qs.stringify(params)].join("?"));
  };

  handleDateRange = (e) => {
    if (e.target.name == "dateStart") {
      this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.name == "dateEnd") {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  submitDateRange = (e) => {
    const { dateStart, dateEnd } = this.state;
    if (
      !moment(dateStart, "YYYY", true).isValid() ||
      !moment(dateEnd, "YYYY", true).isValid()
    ) {
      alert(`請輸入正確起訖日期!`);
      return;
    }

    let params = qs.parseUrl(window.location.href).query;
    params.range = `${dateStart}/${dateEnd}`;
    delete params.eid;
    window.scrollTo(0, 0);
    navigate(["/searchResult", qs.stringify(params)].join("?"));
  };

  getDateRanges = () => {
    let params = qs.parseUrl(window.location.href).query;
    let { range = "" } = params;
    let ranges = range.split("/");
    let dateStart = "",
      dateEnd = "";
    dateStart = ranges[0];
    if (ranges.length > 1) {
      dateEnd = ranges[1];
    }

    ranges = [];

    if (moment(dateStart, "YYYY", true).isValid()) {
      dateStart = moment(dateStart, "YYYY", true).format("YYYY-01");
      ranges.push(dateStart);
    } else {
      dateStart = "";
    }

    if (moment(dateEnd, "YYYY", true).isValid()) {
      dateEnd = moment(dateEnd, "YYYY", true).format("YYYY-12");
      ranges.push(dateEnd);
    } else {
      dateEnd = "";
    }

    return ranges;
  };

  deletePublicationFinder = () => {
    let params = qs.parseUrl(window.location.href).query;
    delete params.filterField;
    delete params.filterValue;
    delete params.eid;
    delete params.publicationId;
    delete params.publicationLabel;
    window.scrollTo(0, 0);
    navigate(["/searchResult", qs.stringify(params)].join("?"));
  };

  render() {
    let { t } = this.props;
    let {
      groups,
      filterPid,
      filters,
      limiter,
      publicationId,
      publicationLabel,
      fulltextLimiterItems,
    } = this.state;

    if (!this.props.display) {
      return null;
    }

    const LoadingBlock = (
      <center>
        <ReactLoading
          type="cylon"
          height={"10%"}
          width={"20%"}
          color="#005D98"
        />
      </center>
    );

    if (filterPid == "") {
      return LoadingBlock;
    }

    const isChinese = this.props.i18n.language == "zh";

    let limit_ft = false;
    let limit_ft1 = false;
    let limit_rv = false;

    limiter.forEach((limit, key) => {
      if (limit == "ft") {
        limit_ft = true;
      } else if (limit == "ft1") {
        limit_ft1 = true;
      } else if (limit == "rv") {
        limit_rv = true;
      }
    });

    let dateRanges = this.getDateRanges();

    return (
      <>
        <div
          className="close"
          tabIndex="0"
          onClick={this.closeFilterBlock}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.addcloseFilterBlockFilter(e);
            }
          }}></div>
        <h2>{t("jumperrwd.common.adjustQuery")}</h2>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getSearchQueryGroup}
            fetchPolicy="network-only"
            ref={(c) => {
              this.queryGroup = c;
            }}
            variables={{
              searchForm: {
                pid: this.state.filterPid,
              },
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading) return LoadingBlock;
              if (error) return "Error!" + error.message;
              if (data.getSearchQueryGroup) {
                let { getSearchQueryGroup } = data;
                let { queryGroupList } = getSearchQueryGroup;

                return (
                  <>
                    <div className="keyword ">
                      <h3>{t("jumperrwd.common.searchFilterBykeyword")}</h3>
                      <form
                        onSubmit={this.addFilter.bind(this, {
                          field: "keyword",
                          value: this.state.keyword,
                          valueName: this.state.keyword,
                        })}>
                        <div className="form_grp form_inline">
                          <input
                            type="text"
                            className=""
                            title="輸入關鍵字"
                            placeholder={t("jumperrwd.common.enterKeyword")}
                            value={this.state.keyword}
                            onChange={this.handleKeyword}
                          />
                          <input
                            name=""
                            type="submit"
                            title={t("jumper.common.search")}
                            value={t("jumper.common.search")}
                          />
                        </div>
                      </form>
                    </div>

                    {(groups.length > 0 ||
                      dateRanges.length > 0 ||
                      publicationId != "") && (
                      <>
                        <div className="conditions">
                          <h3>
                            {t("jumperrwd.common.currectAnalysisCondition")}
                          </h3>
                          <ul>
                            {publicationId != "" && (
                              <li>
                                <h4>相關期刊</h4>
                                <ul>
                                  <li>
                                    <button
                                      type="button"
                                      tabIndex="0"
                                      className="del_btn"
                                      onClick={this.deletePublicationFinder}>
                                      {t("jumperrwd.common.delete")}
                                    </button>
                                    <span>{publicationLabel}</span>
                                  </li>
                                </ul>
                              </li>
                            )}
                            {dateRanges.length > 0 && (
                              <li>
                                <h4>{t("jumper.cluster.publicationdate")}</h4>
                                <ul>
                                  <li>
                                    <button type="button" className="del_btn">
                                      {t("jumperrwd.common.delete")}
                                    </button>
                                    <span>{dateRanges.join(" ~ ")}</span>
                                  </li>
                                </ul>
                              </li>
                            )}
                            {groups.map((item) => (
                              <>
                                <li>
                                  <h4>{item.index}</h4>
                                  <ul>
                                    {item.keyword.map((keyword, key2) => {
                                      let label = item.title[key2];
                                      return (
                                        <li key={`item${key2}`}>
                                          <button
                                            type="button"
                                            tabIndex="0"
                                            className="del_btn"
                                            onClick={() => {
                                              this.deleteFilter({
                                                field: item.index,
                                                value: keyword,
                                              });
                                            }}>
                                            {t("jumperrwd.common.delete")}
                                          </button>
                                          <span>{label}</span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              </>
                            ))}
                          </ul>
                          <button
                            type="button"
                            tabIndex="0"
                            className="del_all"
                            onClick={this.clearFilter}>
                            {t("jumperrwd.common.clearAll")}
                          </button>
                        </div>
                      </>
                    )}

                    <div className="focus">
                      <h3>{t("jumper.cluster.limiter")}</h3>
                      {fulltextLimiterItems.length > 0 && (
                        <div>
                          <h4>{t("jumper.cluster.refineyourresults")}</h4>
                          <ul>
                            {fulltextLimiterItems.includes("ft") && (
                              <li>
                                <input
                                  type="checkbox"
                                  id="limit_ft"
                                  checked={limit_ft}
                                  value="ft"
                                  onChange={this.limiterHandler.bind(this)}
                                />
                                <label htmlFor="limit_ft">
                                  {t("jumper.cluster.fulltext")}
                                </label>
                              </li>
                            )}
                            {fulltextLimiterItems.includes("rv") && (
                              <li>
                                <input
                                  type="checkbox"
                                  id="limit_rv"
                                  checked={limit_rv}
                                  value="rv"
                                  onChange={this.limiterHandler.bind(this)}
                                />
                                <label htmlFor="limit_rv">
                                  {t("jumper.cluster.peerreviewed")}
                                </label>
                              </li>
                            )}
                            {fulltextLimiterItems.includes("ft1") && (
                              <li>
                                <input
                                  type="checkbox"
                                  id="limit_ft1"
                                  checked={limit_ft1}
                                  value="ft1"
                                  onChange={this.limiterHandler.bind(this)}
                                />
                                <label htmlFor="limit_ft1">
                                  {t(
                                    "jumper.cluster.availableinlibrarycollection"
                                  )}
                                </label>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                      <div className="publication_year">
                        <h4>{t("jumper.cluster.publicationdate")}</h4>
                        <div className="range_amount">
                          <input
                            type="tel"
                            name="dateStart"
                            title="出版日期起"
                            maxLength="6"
                            onChange={this.handleDateRange}
                            value={this.state.dateStart}
                          />
                          <span>～</span>
                          <input
                            type="tel"
                            name="dateEnd"
                            title="出版日期迄"
                            maxLength="6"
                            onChange={this.handleDateRange}
                            value={this.state.dateEnd}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        tabIndex="0"
                        className="submit"
                        onClick={this.submitDateRange}>
                        {t("jumperrwd.common.confirm")}
                      </button>
                    </div>
                    <div className="sort_list">
                      <ul>
                        {queryGroupList &&
                          queryGroupList.map((item, key) => {
                            if (item.index == "year") {
                              item.list.sort((a, b) => {
                                if (a.title < b.title) {
                                  return 1;
                                } else if (a.title > b.title) {
                                  return -1;
                                }
                                return 0;
                              });
                            }
                            if (item.list.length > 0) {
                              let groupMessage =
                                "jumperrwd.edsGroup." + item.index;
                              let groupTitle =
                                t(groupMessage) !== groupMessage
                                  ? t(groupMessage)
                                  : item.title;
                              return (
                                <li>
                                  <h3>
                                    <a
                                      tabIndex="0"
                                      className="turnicon"
                                      onClick={this.toggleAccordion}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          this.toggleAccordion(e);
                                        }
                                      }}>
                                      {groupTitle}
                                    </a>
                                  </h3>
                                  <div className="accordion_content">
                                    <ul>{this.groupUL(item)}</ul>
                                    {item.list.length > 1 && (
                                      <>
                                        <button
                                          type="button"
                                          tabIndex="0"
                                          className="more"
                                          onClick={this.popup.bind(this, key)}>
                                          {t("jumperrwd.common.seeMore")}
                                        </button>
                                        <IntegrationSearchGroupLightBox
                                          ref={"gbox_" + key}
                                          type={"eds"}
                                          title={groupTitle}
                                          index={item.index}
                                          data={item.list}
                                          filters={filters}
                                          lightboxClass={"filter_lightbox"}
                                          blockClass={"checkblock"}
                                          t={t}
                                        />
                                      </>
                                    )}
                                  </div>
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </div>
                  </>
                );
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default EdsSearchQueryGroup;
